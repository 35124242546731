import {
    ProviderOAuthType,
} from '~/app/domains/authentication/enums/authentication-provider.enum';
import { ProviderType } from '~/app/domains/authentication/types/provider.type';

export abstract class OAuthProvider {
    private _providerName: ProviderType;

    protected constructor(name: ProviderOAuthType) {
        this._providerName = name ?? ProviderOAuthType.NONE;
    }

    get providerName(): ProviderType {
        return this._providerName;
    }

    set providerName(value: ProviderType) {
        this._providerName = value;
    }


    abstract getLogoutUrl(isUserInternal?: boolean): string;

    abstract getLoginUrl(accountServicerId?: string): string;
}
